<template>
  <div>
    <b-row>
      <b-col
        cols="4"
      >
        <b-form-input
          v-model="user"
          placeholder="E-Mail"
        />
      </b-col>
      <b-col
        cols="8"
      >
        <b-button
          variant="primary"
          type="button"
          @click="searchUser"
        >
          Benutzer Suchen
        </b-button>
      </b-col>
      <b-col
        cols="12"
        style="margin-top:20px;"
      >
        <b-card
          v-if="userData.user"
        >
          <h3>{{ userData.user.firstName }} {{ userData.user.lastName }} ({{ userData.user.email }})</h3>
          <vue-json-pretty :data="userData.user" />
          <vue-json-pretty
            v-if="userData.bonus.length > 0"
            :data="userData.bonus"
          />
          <br>
          <br>
          <b-button
            v-if="userData.bonus.length === 0"
            variant="primary"
            type="button"
            @click="deleteUser(userData.user.id)"
          >
            Benutzer löschen
          </b-button>
          <br>
          <br>
          <b-alert
            v-if="serverResponse"
            variant="danger"
            show
            style="padding:20px;"
          >
            {{ serverResponse }}
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BAlert,
} from 'bootstrap-vue'

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import bonusStoreModule from './backofficeStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    VueJsonPretty,
    BAlert,
  },
  setup(props, ctx) {
    const BONUS_STORE_MODULE = 'backoffice-store-module'
    //  Register BonusStoreModule
    if (!store.hasModule(BONUS_STORE_MODULE)) store.registerModule(BONUS_STORE_MODULE, bonusStoreModule)

    //  Unregister BonusStoreModule
    onUnmounted(() => {
      if (store.hasModule(BONUS_STORE_MODULE)) store.unregisterModule(BONUS_STORE_MODULE)
    })

    const user = ref('')
    const userData = ref([])
    const serverResponse = ref('')

    const searchUser = () => {
      serverResponse.value = ''
      store.dispatch(`${BONUS_STORE_MODULE}/getUser`, user.value)
        .then(response => {
          userData.value = response.data
        })
    }

    const deleteUser = id => {
      serverResponse.value = ''
      ctx.root.$bvModal
        .msgBoxConfirm('Bitte bestätigen!.', {
          title: 'Benutzer löschen?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            console.log('delete user', id)
            store.dispatch(`${BONUS_STORE_MODULE}/deleteUser`, id)
              .then(response => {
                if (response.data === 'OK') {
                  searchUser()
                  user.value = ''
                } else {
                  serverResponse.value = 'Löschen von Benutzer mit VIN nicht erlaubt.'
                }
              })
          }
        })
    }

    return {
      user,
      userData,
      searchUser,
      deleteUser,
      serverResponse,
    }
  },
}
//
</script>
